import React, { useContext } from "react";
import styled from "styled-components";

import { graphql } from "gatsby";

import SEO from "../components/seo";
import Layout from "../components/layout";
import Service from "../components/page-components/service";

import { WebsiteContext } from "../context/WebsiteContext";

const ServiceTemplateContainer = styled.div`
  padding-top: ${props => props.distanceFromTop + "px"};
`;

const ServiceTemplate = ({ data }) => {
  let page = data?.service?.edges[0]?.node || {};
  const { distanceFromTop } = useContext(WebsiteContext);
  return (
    <Layout>
      <SEO title={page.title} />
      <ServiceTemplateContainer distanceFromTop={distanceFromTop}>
        <Service data={data} />
      </ServiceTemplateContainer>
    </Layout>
  );
};

export default ServiceTemplate;

export const query = graphql`
  query($slug: String!) {
    service: allWpService(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          title
          slug
          content
          service_acf {
            serviceBannerSettings {
              bannerHeadline
              bannerImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 90
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                      layout: FULL_WIDTH
                    )
                  }
                }
              }
            }
            serviceOfferingsSettings {
              sectionTitle
              sectionDescription
              offerings {
                offerDescription
                offeringTitle
                offeringUrl
                offerImage {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 90
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                        layout: FULL_WIDTH
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
